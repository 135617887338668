import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import UserActions from '~/store/modules/user/duck';
import { Text } from '~/components/Text';

import Form from './Form';
import { Container, LogoContainer, Logo } from './styles';

function Login() {
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.user.loading);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Email é necessario'),
    password: Yup.string().required('Senha é necessario'),
  });
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  function onSubmit(values) {
    dispatch(UserActions.clearError());
    dispatch(UserActions.loginRequest(values));
  }

  useEffect(() => {
    dispatch(UserActions.clearError());
  }, [dispatch]);

  return (
    <Container>
      <LogoContainer>
        <Logo />
        <Text>
          Com o sistema de rastreio meta track você tem maior estabilidade,
          inteligencia e facilidade na gerencia na movimentação dos seus
          produtos.
        </Text>
      </LogoContainer>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        loading={loading}
        errors={errors}
      />
    </Container>
  );
}

export default Login;
