import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import handleError from '~/adapters/httpErrors';
import CompaniesActions, { CompaniesTypes } from './duck';

export function* getCompanies(action) {
  try {
    const { filters } = action;
    const response = yield call(api.get, `/companies`, {
      params: filters,
    });
    const { data, next } = response.data;
    yield put(CompaniesActions.getCompaniesSuccess(data, next));
  } catch (err) {
    yield put(CompaniesActions.getCompaniesError('Erro ao buscar os dados'));
  }
}

export function* getCompany(action) {
  try {
    const { id } = action;
    const response = yield call(api.get, `/companies/${id}`);
    const { data } = response;
    yield put(CompaniesActions.getCompanySuccess(data));
  } catch (err) {
    const error = handleError(err);
    yield put(CompaniesActions.getCompanyError(error));
  }
}

export function* addCompany(action) {
  try {
    yield call(api.post, '/companies', action.data);
    yield put(CompaniesActions.addCompanySuccess());
    toast.success('Empresa cadastrada com sucesso');
    history.push('/companies');
  } catch (err) {
    const error = handleError(err);
    yield put(CompaniesActions.addCompanyError(error));
  }
}

export function* updateCompany(action) {
  try {
    const { id, values } = action;
    const response = yield call(api.put, `/companies/${id}`, values);
    const { data } = response;
    yield put(CompaniesActions.updateCompanySuccess(data));
    toast.success('Empresa atualizada com sucesso!');
  } catch (err) {
    const error = handleError(err);
    yield put(CompaniesActions.updateCompanyError(error));
  }
}

export function* getSettings({ companyId }) {
  try {
    const { data } = yield call(api.get, `/companies/${companyId}/settings`);
    const file = new Blob([JSON.stringify(data)], { type: 'text/json' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `settings.json`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (err) {
    const error = handleError(err);
    yield put(CompaniesActions.updateCompanyError(error));
  }
}

export default all([
  takeLatest(CompaniesTypes.GET_COMPANIES_REQUEST, getCompanies),
  takeLatest(CompaniesTypes.GET_COMPANY_REQUEST, getCompany),
  takeLatest(CompaniesTypes.ADD_COMPANY_REQUEST, addCompany),
  takeLatest(CompaniesTypes.UPDATE_COMPANY_REQUEST, updateCompany),
  takeLatest(CompaniesTypes.GET_SETTINGS_REQUEST, getSettings),
]);
