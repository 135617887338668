import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import GlobalStyle from '~/styles/GlobalStyle';
import Login from '~/pages/Shared/Login';
import NotFound from '~/pages/Shared/404';
import PasswordRecovery from '~/pages/Shared/User/PasswordRecovery';
import RecoveryUpdate from '~/pages/Shared/User/RecoveryUpdate';
import ActivateUser from '~/pages/Shared/User/Activate';
import CodeInfo from '~/pages/Shared/CodeInfo';

import AppRoute from './PrivateRouter';
import AuthRoutes from './AuthRoutes';

function Routes() {
  return (
    <>
      <GlobalStyle />
      <ToastContainer autoClose={3000} position="top-right" />
      <Switch>
        <Route path="/codes" exact component={CodeInfo} />
        <Route path="/codes/:code" component={CodeInfo} />

        <AppRoute path="/404" component={NotFound} />
        <AppRoute path="/login" component={Login} isPublicRoute />
        <AppRoute
          path="/recovery"
          exact
          component={PasswordRecovery}
          isPublicRoute
        />
        <AppRoute
          path="/recovery/:code"
          component={RecoveryUpdate}
          isPublicRoute
        />
        <AppRoute
          path="/activate/:code"
          component={ActivateUser}
          isPublicRoute
        />

        <AppRoute path="/" component={AuthRoutes} isPrivateRoute />

        {/* <Route path="/user/dashboard" component={AuthRoutes} isPrivateRoute /> */}
      </Switch>
    </>
  );
}

export default Routes;
