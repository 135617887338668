import styled from 'styled-components';

import LogoImage from '~/assets/images/logo.svg';
import { Title, Text } from '~/components/Text';
import Search from '~/components/Search';

export const Container = styled.main`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  background-color: ${(props) => props.theme.colors.grayScale.variation10};
`;

export const Header = styled.header`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: grid;
  width: 100%;
  min-height: min-content;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  column-gap: 12rem;
  align-items: center;
  padding: 0.4rem 0.8rem;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  z-index: 999;

  > div {
    justify-self: end;
  }
`;

export const CodeContainer = styled.section`
  width: 100%;
  max-width: 720px;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr min-content;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  row-gap: 3rem;
  padding: 120px 2rem 2rem;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-top: none;
  border-radius: 0 0 8px 8px;
`;

export const Logo = styled.img.attrs({
  src: LogoImage,
})`
  width: 160px;
`;

export const HeaderSearch = styled(Search)`
  min-width: 18rem;
`;

export const CodeBox = styled.div`
  width: min-content;
  justify-self: center;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.grayScale.variation8};
  border: 1px solid ${(props) => props.theme.colors.grayScale.variation7};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
`;

export const Code = styled(Title)`
  text-align: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grayScale.base};
`;

export const InfoContainer = styled.section`
  width: ${(props) => props.loading && '100%'};
  display: grid;
  grid-template-rows: ${(props) =>
    props.loading ? 'minmax(58vh, 1fr)' : 'auto'};
  grid-auto-rows: auto;
  grid-template-columns: ${(props) =>
    props.loading ? '1fr' : 'repeat(3, 1fr)'};
  justify-content: center;
  row-gap: 1.6rem;
  column-gap: 3rem;
`;

export const InfoBox = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'grid')};
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: max-content;
  justify-items: start;
  gap: 0.2rem;
  text-align: center;
`;

export const InfoLabel = styled(Title)`
  font-size: 16px;
  font-weight: 700;
`;

export const InfoData = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;
