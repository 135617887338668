import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import UserActions from '~/store/modules/user/duck';
import Row from '~/components/Row';
import { Text } from '~/components/Text';
import Error from '~/components/Form/Error';
import { Button } from '~/components/Form/Buttons';
import { Label, Field } from '~/components/Form/Fields';

import {
  Container,
  FormTitle,
  StyledBox,
  StyledFieldContainer,
} from './styles';

function ActivateUser() {
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.user.loading);
  const error = useSelector((store) => store.user.error);
  const send = useSelector((store) => store.user.send);
  const { code } = useParams();

  const schema = Yup.object().shape({
    password: Yup.string()
      .required('Preencha a senha, por favor')
      .min(8, 'A senha deve ter no mínimo 8 dígitos')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.{8,})/,
        'Deve ter uma letra minúscula, uma maiúscula, um número e um caractere especial'
      ),
    confirmPassword: Yup.string()
      .required('Confime a senha, por favor')
      .oneOf([Yup.ref('password')], 'As senhas não conferem'),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  function onSubmit(values) {
    dispatch(UserActions.clearError());

    dispatch(UserActions.activateRequest(code, values.password));
  }

  function sendNewCode() {
    dispatch(UserActions.clearError());

    dispatch(UserActions.sendNewActivateCodeRequest(code));
  }

  useEffect(() => {
    dispatch(UserActions.clearError());
  }, [dispatch]);

  return (
    <Container>
      <StyledBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledFieldContainer>
            <Row justify="center">
              <FormTitle>Ative sua conta</FormTitle>
            </Row>
          </StyledFieldContainer>

          <StyledFieldContainer>
            <Label className="required">Senha</Label>
            <Field type="password" name="password" ref={register} />

            <ErrorMessage errors={errors} name="password">
              {({ message }) => <Error>{message}</Error>}
            </ErrorMessage>
          </StyledFieldContainer>

          <StyledFieldContainer>
            <Label className="required">Confirmar Senha</Label>
            <Field type="password" name="confirmPassword" ref={register} />

            <ErrorMessage errors={errors} name="confirmPassword">
              {({ message }) => <Error>{message}</Error>}
            </ErrorMessage>

            {error && <Error>{error}</Error>}
            {send && <Text>Conta ativada</Text>}
          </StyledFieldContainer>

          <StyledFieldContainer>
            <Button themeType="primary" type="submit" disabled={loading}>
              Enviar
            </Button>
          </StyledFieldContainer>

          <StyledFieldContainer>
            <Button type="button" onClick={sendNewCode} disabled={loading}>
              Enviar novamente
            </Button>
          </StyledFieldContainer>
        </form>
      </StyledBox>
    </Container>
  );
}

export default ActivateUser;
