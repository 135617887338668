import { all, takeLatest, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import handleError from '~/adapters/httpErrors';
import EmployeesActions, { EmployeesTypes } from './duck';

export function* addEmployee(action) {
  try {
    yield call(api.post, '/employees', action.data);
    yield put(EmployeesActions.addEmployeeSuccess());

    toast.success('Funcionário cadastrado.');
    history.push('/users');
  } catch (err) {
    const error = handleError(err);
    yield put(EmployeesActions.addEmployeeError(error));
  }
}

export function* getEmployees(action) {
  try {
    const { filters } = action;

    const response = yield call(api.get, `/employees`, {
      params: filters,
    });

    const { data, next } = response.data;
    yield put(EmployeesActions.getEmployeesSuccess(data, next));
  } catch (err) {
    const error = handleError(err);
    yield put(EmployeesActions.getEmployeesError(error));
  }
}

export function* getEmployee(action) {
  try {
    const { id } = action;
    const response = yield call(api.get, `/employees/${id}`);
    const { data } = response;
    yield put(EmployeesActions.getEmployeeSuccess(data));
  } catch (err) {
    const error = handleError(err);
    yield put(EmployeesActions.getEmployeeError(error));
  }
}

export function* updateEmployee(action) {
  try {
    const { id, role_id: role, email, active } = action.data;

    const data = {
      email,
      role_id: role || null,
      active,
    };

    const response = yield call(api.put, `/employees/${id}`, data);

    yield put(EmployeesActions.updateEmployeeSuccess(response.data));
    toast.success('Usuário atualizado.');
  } catch (err) {
    const error = handleError(err);
    yield put(EmployeesActions.getEmployeeError(error));
  }
}

export default all([
  takeLatest(EmployeesTypes.ADD_EMPLOYEE_REQUEST, addEmployee),
  takeLatest(EmployeesTypes.UPDATE_EMPLOYEE_REQUEST, updateEmployee),
  takeLatest(EmployeesTypes.GET_EMPLOYEES_REQUEST, getEmployees),
  takeLatest(EmployeesTypes.GET_EMPLOYEE_REQUEST, getEmployee),
]);
