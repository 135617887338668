import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  setTitleHeader: ['data'],
});

export const DashboardTypes = Types;
export default Creators;

const INITIAL_STATE = {
  page: [],
  goTo: {
    enable: false,
    destination: '',
  },
  loading: false,
  error: '',
};

function setTitleHeader(state = INITIAL_STATE, action) {
  return {
    ...state,
    page: action.data.page,
    goTo: action.data.goTo || state.goTo,
    loading: false,
    error: '',
  };
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_TITLE_HEADER]: setTitleHeader,
});
