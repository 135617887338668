import React from 'react';
import PropTypes from 'prop-types';
import { MdSearch } from 'react-icons/md';

import { SearchContainer, SearchInput, SearchIcon } from './styles';

function Search({ onSubmit, clearSearch, disabled, ...props }) {
  const handleKeyUp = (e) => {
    if (e.key === 'Enter') onSubmit();
    if (e.key === 'Escape') clearSearch();
  };

  return (
    <SearchContainer>
      <SearchInput onKeyUp={handleKeyUp} {...props} disabled={disabled} />
      <SearchIcon onClick={onSubmit} disabled={disabled}>
        <MdSearch />
      </SearchIcon>
    </SearchContainer>
  );
}

Search.propTypes = {
  onSubmit: PropTypes.func,
  clearSearch: PropTypes.func,
  disabled: PropTypes.bool,
};

Search.defaultProps = {
  onSubmit: () => {},
  clearSearch: () => {},
  disabled: false,
};

export default Search;
