import { combineReducers } from 'redux';

import { reducer as user } from './user/duck';
import { reducer as companies } from './companies/duck';
import { reducer as contacts } from './contacts/duck';
import { reducer as dashboard } from './dashboard/duck';
import { reducer as employees } from './employees/duck';
import { reducer as roles } from './roles/duck';
import { reducer as permissions } from './permissions/duck';

const reducers = combineReducers({
  user,
  companies,
  employees,
  contacts,
  dashboard,
  roles,
  permissions,
});

export default reducers;
