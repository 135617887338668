import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdAccountCircle } from 'react-icons/md';

import { SubTitle } from '~/components/Text';

export const Container = styled.nav`
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: ${(props) => props.theme.sizes.header};
  background-color: ${(props) => props.theme.colors.background};
  padding: 15px 45px 15px 275px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14),
    0px 3px 4px rgba(0, 0, 0, 0.12);

  @media screen and (max-width: 767px) {
    padding: 1rem;
  }
`;

export const Headline = styled(SubTitle)`
  font-size: 20px;
`;

export const IconContent = styled.span`
  position: relative;
  display: flex;
`;

export const Icon = styled(MdAccountCircle)`
  font-size: calc(${(props) => props.theme.sizes.title} + 2px);
  cursor: pointer;
  transition: fill 0.3s;

  &:hover {
    fill: ${(props) => props.theme.colors.primary};
  }

  &.active {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const Arrow = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  height: 20px;
  width: 20px;
  bottom: 100%;
  left: 85%;
  border-top-width: 10px;
  border-top-color: transparent;
  border-right-width: 10px;
  border-right-color: #696969;
  border-bottom-width: 10px;
  border-bottom-color: transparent;
  border-left-width: 0px;
  border-left-color: transparent;
  transform: rotate(90deg);
`;

export const Options = styled.div`
  position: absolute;
  display: none;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  right: 0;
  top: ${(props) => props.theme.sizes.header};
  padding: 7px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14),
    0px 3px 4px rgba(0, 0, 0, 0.12);

  &.show {
    display: flex;
  }
`;

export const Option = styled(Link)`
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: 1px solid #696969;
  background-color: transparent;
  padding: 7px;
  margin: 0px 5px;
  width: 100%;
  cursor: pointer;
  font-size: 18px;

  & > p {
    padding-left: 12px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &.settings {
    transition: all 0.3s;

    &:hover svg {
      transition: all 0.3s;
      transform: rotate(180deg);
    }
  }

  &.logout {
    transition: all 0.3s;

    &:hover svg {
      transition: all 0.3s;
      transform: translateX(-5px);
    }
  }
`;
