import styled from 'styled-components';

import { FieldContainer } from '~/components/Form/Fields';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || 'center'};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  font-size: ${(props) => props.theme.sizes.text};
  font-weight: bold;
  padding: 0.8rem;
  transition: box-shadow 0.3s, color 0.3s, background 0.5s;

  &:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14),
      0px 3px 4px rgba(0, 0, 0, 0.12);
  }

  &.primary {
    background-color: ${(props) =>
      props.disabled ? 'rgba(0,0,0,0.3)' : props.theme.colors.primary};
    color: #fff;
  }

  &.secondary {
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};

    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
      color: #fff;
    }
  }

  svg {
    font-size: calc(${(props) => props.theme.sizes.text} + 5px);
    margin-right: 7px;
  }
`;

export const ButtonContainer = styled(FieldContainer)`
  width: 100%;
  flex-direction: ${(props) => props.flexDir || 'initial'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  align-items: ${(props) => props.alignItems || 'center'};
  margin: 0;
  padding: ${(props) => props.padding || '0 0 0 0'};

  > button:not(:first-child) {
    margin-left: 15px;
  }
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 7px;
  font-size: ${(props) => props.size || props.theme.sizes.subTitle};
  color: ${(props) => props.color || props.theme.colors.text};
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.color || props.theme.colors.primary};
  }
`;

export const CircleButton = styled.button`
  position: fixed;
  display: flex;
  right: 24px;
  bottom: 24px;
  height: 42px;
  width: 42px;
  border: none;
  border-radius: 21px;
  color: white;
  align-self: center;
  justify-content: center;
  font-size: 28px;
  background-color: ${(props) => props.theme.colors.primary};
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14),
      0px 3px 4px rgba(0, 0, 0, 0.12);
  }

  svg {
    margin: auto;
  }
`;
