import styled, { css } from 'styled-components';
import ArrowDownSelect from '~/assets/images/arrow-down.svg';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.text};

  &.required::after {
    content: ' *';
    color: tomato;
  }
`;

export const FieldBase = css`
  width: 100%;
  font-size: ${(props) => props.theme.sizes.text};
  color: ${(props) => props.theme.colors.text};
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  padding: 1rem;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2), 0px 1px 5px rgba(0, 0, 0, 0.14),
      0px 3px 1px rgba(0, 0, 0, 0.12);
  }

  &:focus {
    outline: none;
  }
`;

export const FieldArea = styled.textarea`
  ${FieldBase}
`;

export const Field = styled.input`
  ${FieldBase}

  &[type='checkbox'] {
    width: auto;
    margin: 7px 5px 7px 0px;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
  }

  &[type='radio']:hover,
  &:disabled:hover {
    box-shadow: none;
  }

  &:read-only {
    background-color: ${(props) => props.theme.colors.page};

    &:hover {
      box-shadow: none;
    }
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #215253;
  cursor: pointer;
  transition: box-shadow 0.3s;
  outline: none;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${ArrowDownSelect});
  background-repeat: no-repeat;
  background-position: 95% center;

  &:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14),
      0px 3px 4px rgba(0, 0, 0, 0.12);
  }

  &::-ms-expand {
    display: none;
  }

  option {
    font-size: 14px;
    background-color: #fff;
    color: #000;
  }
`;
