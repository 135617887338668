import styled from 'styled-components';
import { darken } from 'polished';

export const SearchContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr auto;
  grid-auto-flow: column;
  border-radius: ${(props) => props.theme.sizes.border};
  background-color: ${(props) => props.theme.colors.background};
  overflow: hidden;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: ${(props) => props.theme.sizes.text};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-right: none;
  border-radius: 0;
  border-top-left-radius: ${(props) => props.theme.sizes.borderRadius};
  border-bottom-left-radius: ${(props) => props.theme.sizes.borderRadius};
  transition: border-color 0.3s;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors.grayScale.variation6};
  }
`;

export const SearchIcon = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  color: white;
  background-color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.sizes.subTitle};
  border: none;
  border-radius: 0;
  border-top-right-radius: ${(props) => props.theme.sizes.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.sizes.borderRadius};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.primary)};

    > div svg {
      color: #fff;
    }
  }

  &:disabled {
    background-color: #ced2d9;
    color: ${(props) => props.theme.colors.primary};
    cursor: default;

    &:hover {
      background-color: #ced2d9;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
