import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPermissionsRequest: [],
  getPermissionsSuccess: ['data'],
  getPermissionsError: ['error'],
});

export const PermissionsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  data: [],
};

function getPermissionsRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function getPermissionsSuccess(state = INITIAL_STATE, action) {
  return { ...state, loading: false, data: action.data };
}

function getPermissionsError(state = INITIAL_STATE, action) {
  return { ...state, action: action.data, loading: false };
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PERMISSIONS_REQUEST]: getPermissionsRequest,
  [Types.GET_PERMISSIONS_SUCCESS]: getPermissionsSuccess,
  [Types.GET_PERMISSIONS_ERROR]: getPermissionsError,
});
