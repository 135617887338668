import React from 'react';
import PropTypes from 'prop-types';

import MenuOption from './MenuOption';

import Row from '~/components/Row';

import LogoImage from '~/assets/images/logo.svg';

import { SubTitle } from '~/components/Text';
import { Container, MenuList, Logo } from './styles';

function SideMenu({ items }) {
  return (
    <Container>
      <Row justify="center">
        <Logo alt="logo" src={LogoImage} />
        <SubTitle>Meta Track</SubTitle>
      </Row>
      <MenuList>
        {items.map((item) => (
          <MenuOption
            to={item.pathTo}
            key={item.text}
            exact={item.exact || false}
          >
            {item.icon}
            {item.text}
          </MenuOption>
        ))}
      </MenuList>
    </Container>
  );
}

SideMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default React.memo(SideMenu);
