import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

// import { id } from 'date-fns/locale';
import api from '~/services/api';
import handleError from '~/adapters/httpErrors';
import RolesActions, { RolesTypes } from './duck';

export function* getRoles() {
  try {
    const response = yield call(api.get, `/roles`);
    const { data } = response;
    yield put(RolesActions.getRolesSuccess(data));
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.getRolesError(error));
  }
}

export function* addRole(action) {
  try {
    const response = yield call(api.post, `/roles`, action.data);
    const { data } = response;
    toast.success('Função criada.');
    yield put(RolesActions.addRolesSuccess(data));
    yield put(RolesActions.getRolesRequest());
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.addRolesError(error));
  }
}

export function* getRole(action) {
  try {
    const { id } = action;
    const response = yield call(api.get, `/roles/${id}`);
    const { data } = response;
    yield put(RolesActions.getRoleSuccess(data));
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.getRoleError(error));
  }
}

export function* updateRoles(action) {
  try {
    const { data } = action;
    const { id } = data;
    console.log(id);
    const response = yield call(api.put, `/roles/${id}`, data);
    yield put(RolesActions.updateRolesSuccess(response.data));
    toast.success('Regras atualizadas com sucesso.');
  } catch (err) {
    const error = handleError(err);
    yield put(RolesActions.updateRolesError(error));
  }
}

export default all([
  takeLatest(RolesTypes.GET_ROLES_REQUEST, getRoles),
  takeLatest(RolesTypes.ADD_ROLES_REQUEST, addRole),
  takeLatest(RolesTypes.UPDATE_ROLES_REQUEST, updateRoles),
  takeLatest(RolesTypes.GET_ROLE_REQUEST, getRole),
]);
