import { all } from 'redux-saga/effects';

import user from './user/sagas';
import companies from './companies/sagas';
import employees from './employees/sagas';
import contacts from './contacts/sagas';
import roles from './roles/sagas';
import permissions from './permissions/sagas';

export default function* rootSaga() {
  yield all([user, companies, employees, contacts, roles, permissions]);
}
