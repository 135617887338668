import styled from 'styled-components';
import Box from '~/components/Box';

import srcLogo from '~/assets/images/new-logo.svg';
import { FieldContainer } from '~/components/Form/Fields';
import { Title } from '~/components/Text';

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: 767px) {
    flex-flow: column nowrap;
  }
`;

export const LogoContainer = styled.div`
  flex: 2;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #215253;

  p {
    max-width: 400px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
`;

export const Logo = styled.img.attrs({
  src: srcLogo,
})`
  width: 150px;
  height: 150px;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    width: 100px;
  }
`;

export const FormContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  border: none;
  form {
    padding: 1rem;

    button[type='submit'] {
      margin-top: 15px;
    }
  }
`;

export const LinkContainer = styled(FieldContainer)`
  margin-top: 12px;
`;

export const FormTitle = styled(Title)`
  color: ${(props) => props.theme.colors.primary};
  padding-bottom: 45px;
`;
