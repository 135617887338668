import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { permissionsArray, checkPermission } from '~/services/auth';

export default function PrivateRouter({
  component: Component,
  // isPrivateRoute,
  isPublicRoute,
  permission,
  ...rest
}) {
  const signed = useSelector((store) => store.user.token);
  const role = useSelector((store) => store.user.role);

  if (!isPublicRoute && !signed) return <Redirect to="/login" />;

  if (isPublicRoute && signed) return <Redirect to="/" />;

  if (permission && !checkPermission(role, permission))
    return <Redirect to="/" />;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

PrivateRouter.propTypes = {
  isPrivateRoute: PropTypes.bool,
  isAdminRoute: PropTypes.bool,
  isUserRoute: PropTypes.bool,
  isPublicRoute: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  permission: PropTypes.oneOfType([
    PropTypes.oneOf(permissionsArray),
    PropTypes.string,
  ]),
};

PrivateRouter.defaultProps = {
  isPrivateRoute: false,
  isPublicRoute: false,
  isAdminRoute: false,
  isUserRoute: false,
  permission: '',
};
