import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  getRolesRequest: [],
  getRolesSuccess: ['data'],
  getRolesError: ['error'],
  getRoleRequest: ['id'],
  getRoleSuccess: ['data'],
  getRoleError: ['error'],
  addRolesRequest: ['data'],
  addRolesSuccess: [],
  addRolesError: ['error'],
  updateRolesRequest: ['data'],
  updateRolesSuccess: ['data'],
  updateRolesError: ['error'],
});

export const RolesTypes = Types;
export default Creators;

const INITIAL_STATE = {
  data: [],
};

function getRolesRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function getRolesSuccess(state = INITIAL_STATE, action) {
  return { ...state, loading: false, data: action.data };
}

function getRolesError(state = INITIAL_STATE, action) {
  return { ...state, action: action.data, loading: false };
}

function getRoleRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function getRoleSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    selectedRole: action.data,
    loading: false,
  };
}

function getRoleError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function addRolesRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function addRolesSuccess(state = INITIAL_STATE) {
  return { ...state, loading: false };
}

function addRolesError(state = INITIAL_STATE, action) {
  return { ...state, action: action.data, loading: false };
}

function updateRolesSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    selectedRole: action.data,
    error: '',
    loading: false,
  };
}

function genericError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function genericRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ROLES_REQUEST]: getRolesRequest,
  [Types.GET_ROLES_SUCCESS]: getRolesSuccess,
  [Types.GET_ROLES_ERROR]: getRolesError,
  [Types.GET_ROLE_REQUEST]: getRoleRequest,
  [Types.GET_ROLE_SUCCESS]: getRoleSuccess,
  [Types.GET_ROLE_ERROR]: getRoleError,
  [Types.ADD_ROLES_REQUEST]: addRolesRequest,
  [Types.ADD_ROLES_SUCCESS]: addRolesSuccess,
  [Types.ADD_ROLES_ERROR]: addRolesError,
  [Types.UPDATE_ROLES_REQUEST]: genericRequest,
  [Types.UPDATE_ROLES_SUCCESS]: updateRolesSuccess,
  [Types.UPDATE_ROLES_ERROR]: genericError,
});
