import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { MdWork, MdPerson, MdSecurity } from 'react-icons/md';

import { checkPermission } from '~/services/auth';
import Header from '~/components/Header';
import SideMenu from '~/components/SideMenu';
import Loader from '~/components/Loader';

import { Container, Content } from './styles';

function AdminRoutes() {
  const role = useSelector((store) => store.user.role);

  const allItems = [
    // "meta"
    {
      icon: <MdWork />,
      text: 'Empresas',
      pathTo: '/companies',
      permission: 'index:company',
      type: 'meta',
    },
    {
      icon: <MdPerson />,
      text: 'Usuários',
      pathTo: '/users',
      permission: 'index:employee',
      type: 'meta',
    },
    {
      icon: <MdSecurity />,
      text: 'Segurança',
      pathTo: '/roles',
      permission: 'store:role',
      type: 'company',
    },
  ];

  const items = allItems.filter((item) =>
    checkPermission(role, item.permission, item.type)
  );

  const MetaRoutes = lazy(() => import('./MetaRoutes'));

  return (
    <Container>
      <Header />
      <SideMenu items={items} />
      <Content>
        <Suspense fallback={<Loader />}>
          <MetaRoutes />
        </Suspense>
      </Content>
    </Container>
  );
}

export default AdminRoutes;
