import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import history from '~/services/history';
import getPosition from '~/services/getPosition';
import getCodeInfo from '~/services/getCodeInfo';
import { companiesEnum } from '~/enums';

import Loading from '~/components/Loading';
import {
  Container,
  Header,
  Logo,
  HeaderSearch,
  CodeContainer,
  CodeBox,
  Code,
  InfoContainer,
} from './styles';

const CodeInfo = () => {
  const [search, setSearch] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  // const [read, setRead] = useState(false);
  const [codeData, setCodeData] = useState({});
  const { code } = useParams();
  const DataComponent = companiesEnum[code ? codeData?.company : 'noData'];

  useEffect(() => {
    const loadCode = async () => {
      if (!code) return;
      // if (read) return;
      setLocalLoading(true);
      const position = await getPosition();
      const havePosition = position?.latitude && position?.longitude;
      if (!havePosition) {
        toast.error('É necessário permitir o acesso a sua localização.');
        return;
      }
      const data = await getCodeInfo(code);
      setCodeData(data);
      setLocalLoading(false);
      // setRead(true);
    };

    loadCode();
  }, [code]);

  const handleClearSearch = () => setSearch('');

  const handleSearch = () => history.push(`/codes/${search}`);

  return (
    <Container>
      <Header>
        <Logo />
        <HeaderSearch
          placeholder="Pesquisar outro código"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSubmit={handleSearch}
          clearSearch={handleClearSearch}
          disabled={localLoading}
        />
      </Header>
      <CodeContainer>
        <CodeBox>
          <Code>{code}</Code>
        </CodeBox>
        <InfoContainer loading={localLoading}>
          {localLoading && <Loading />}
          {!localLoading && DataComponent && <DataComponent data={codeData} />}
        </InfoContainer>
      </CodeContainer>
    </Container>
  );
};

export default CodeInfo;
