import React from 'react';

import { InfoBox, InfoData } from '../styles';

const ImbelData = () => (
  <>
    <InfoBox>
      <InfoData>Não a código</InfoData>
    </InfoBox>
  </>
);

export default ImbelData;
