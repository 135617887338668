import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCompaniesRequest: ['filters'],
  getCompaniesSuccess: ['companies', 'next'],
  getCompaniesError: ['error'],
  getCompanyRequest: ['id'],
  getCompanySuccess: ['data'],
  getCompanyError: ['error'],
  addCompanyRequest: ['data'],
  addCompanySuccess: [''],
  addCompanyError: ['error'],
  updateCompanyRequest: ['id', 'values'],
  updateCompanySuccess: ['data'],
  updateCompanyError: ['error'],
  getSettingsRequest: ['companyId'],
  getSettingsSuccess: [],
  getSettingsError: ['error'],
  setPage: ['page'],
  clearCompany: [],
});

export const CompaniesTypes = Types;
export default Creators;

const INITIAL_STATE = {
  data: [],
  selectedCompany: {},
  loading: false,
  error: '',
  page: 1,
  next: null,
};

function getCompaniesSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    data: action.companies,
    page: state.page + 1,
    next: action.next,
    loading: false,
    error: '',
  };
}

function getCompanySuccess(state = INITIAL_STATE, action) {
  return { ...state, selectedCompany: action.data, loading: false };
}

function updateCompanySuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    selectedCompany: {
      employees: state.selectedCompany.employees,
      ...action.data,
    },
    loading: false,
  };
}

function genericRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function genericSuccess(state = INITIAL_STATE) {
  return { ...state, loading: false, error: '' };
}

function genericError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function setPage(state = INITIAL_STATE, action) {
  return { ...state, page: action.page };
}

function clearCompany() {
  return INITIAL_STATE;
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COMPANIES_REQUEST]: genericRequest,
  [Types.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
  [Types.GET_COMPANIES_ERROR]: genericError,
  [Types.GET_COMPANY_REQUEST]: genericRequest,
  [Types.GET_COMPANY_SUCCESS]: getCompanySuccess,
  [Types.GET_COMPANY_ERROR]: genericError,
  [Types.ADD_COMPANY_REQUEST]: genericRequest,
  [Types.ADD_COMPANY_SUCCESS]: genericSuccess,
  [Types.ADD_COMPANY_ERROR]: genericError,
  [Types.UPDATE_COMPANY_REQUEST]: genericRequest,
  [Types.UPDATE_COMPANY_SUCCESS]: updateCompanySuccess,
  [Types.UPDATE_COMPANY_ERROR]: genericError,
  [Types.GET_SETTINGS_REQUEST]: genericRequest,
  [Types.GET_SETTINGS_SUCCESS]: genericSuccess,
  [Types.GET_SETTINGS_ERROR]: genericRequest,
  [Types.SET_PAGE]: setPage,
  [Types.CLEAR_COMPANY]: clearCompany,
});
