import { toast } from 'react-toastify';

function isNotHttpError(err) {
  if (!err.response) return true;

  return false;
}

function isServerError(status) {
  return status === 500;
}

function getErrorMessage(err) {
  if (isNotHttpError(err))
    return 'Erro na conexão com servidor, tente novamente mais tarde.';

  const { response } = err;
  if (isServerError(response.status))
    return 'Erro interno do servidor, tente novamente mais tarde.';

  const { error } = response.data;
  return error;
}

export default function handleError(err) {
  const error = getErrorMessage(err);
  toast.error(error, { autoClose: 5000 });
  return error;
}
