import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'react-hook-form';

import Row from '~/components/Row';
import Error from '~/components/Form/Error';
import { Button } from '~/components/Form/Buttons';
import { Link } from '~/components/Text';
import { FieldContainer, Label, Field } from '~/components/Form/Fields';

import { FormContainer, FormTitle, LinkContainer } from './styles';

function Form(props) {
  const { onSubmit, register, loading, errors } = props;

  return (
    <FormContainer>
      <form onSubmit={onSubmit}>
        <FieldContainer padding="1rem 0 0">
          <Row justify="center">
            <FormTitle>Meta Track</FormTitle>
          </Row>
        </FieldContainer>

        <FieldContainer padding="1rem 0 0">
          <Label>Email</Label>
          <Field name="email" ref={register} />

          <ErrorMessage errors={errors} name="email">
            {({ message }) => <Error>{message}</Error>}
          </ErrorMessage>
        </FieldContainer>

        <FieldContainer padding="1rem 0 0">
          <Label>Senha</Label>
          <Field type="password" name="password" ref={register} />

          <ErrorMessage errors={errors} name="password">
            {({ message }) => <Error>{message}</Error>}
          </ErrorMessage>
        </FieldContainer>

        <FieldContainer padding="1rem 0">
          <Button className="primary" type="submit" disabled={loading}>
            Login
          </Button>
        </FieldContainer>

        <LinkContainer>
          <Link align="center" to="/recovery">
            Esqueceu a senha
          </Link>
        </LinkContainer>
      </form>
    </FormContainer>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
};

export default Form;
