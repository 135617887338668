import React from 'react';
import PropTypes from 'prop-types';

import { InfoBox, InfoLabel, InfoData } from '../styles';

const ImbelData = ({ data }) => (
  <>
    <InfoBox hidden={!data?.product}>
      <InfoLabel>Produto:</InfoLabel>
      <InfoData>{data?.product}</InfoData>
    </InfoBox>
    <InfoBox>
      <InfoLabel>Status:</InfoLabel>
      <InfoData>{data?.isBlocked}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.observation}>
      <InfoLabel>Observação:</InfoLabel>
      <InfoData>{data?.observation}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.volume}>
      <InfoLabel>Volume: </InfoLabel>
      <InfoData>{data?.volume}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.partition}>
      <InfoLabel>Partição:</InfoLabel>
      <InfoData>{data?.partition}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.stackingLimit}>
      <InfoLabel>Limite de empilhamento:</InfoLabel>
      <InfoData>{data?.stackingLimit}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.onuNumber}>
      <InfoLabel>Número ONU:</InfoLabel>
      <InfoData>{data?.onuNumber}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.warning}>
      <InfoLabel>Aviso de perigo:</InfoLabel>
      <InfoData>{data?.warning}</InfoData>
    </InfoBox>
    <InfoBox hidden={!data?.usefulInformation}>
      <InfoLabel>Informações úteis:</InfoLabel>
      <InfoData>{data?.usefulInformation}</InfoData>
    </InfoBox>
  </>
);

ImbelData.propTypes = {
  data: PropTypes.shape({
    companyId: PropTypes.number,
    value: PropTypes.string,
    dynamic: PropTypes.string,
    observation: PropTypes.string,
    isBlocked: PropTypes.string,
    volume: PropTypes.string,
    partition: PropTypes.bool,
    stackingLimit: PropTypes.bool,
    warning: PropTypes.bool,
  }).isRequired,
};

export default ImbelData;
