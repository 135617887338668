import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  setUser: ['data'],
  getProfileRequest: [],
  getProfileSuccess: ['data'],
  getProfileError: ['error'],
  updateProfileRequest: ['data'],
  updateProfileSuccess: ['data'],
  updateProfileError: ['error'],
  clearError: [],
  loginRequest: ['data'],
  loginSuccess: ['user'],
  loginError: ['error'],
  confirmCodeRequest: ['code', 'password'],
  sendNewCodeRequest: ['code'],
  sendNewCodeSuccess: [],
  sendNewCodeError: ['error'],
  sendRecoveryCodeRequest: ['email'],
  sendRecoveryCodeSuccess: [],
  sendRecoveryCodeError: ['error'],
  recoveryRequest: ['code', 'password'],
  recoverySuccess: [],
  recoveryError: ['error'],
  sendNewActivateCodeRequest: ['code'],
  sendNewActivateCodeSuccess: [],
  sendNewActivateCodeError: ['error'],
  activateRequest: ['code', 'password'],
  activateSuccess: [],
  activateError: ['error'],
  logout: [],
});

export const UserTypes = Types;
export default Creators;

const INITIAL_STATE = {
  name: '',
  email: '',
  token: '',
  loading: false,
  send: false,
  error: '',
  role: {
    permissions: [],
    type: 'company',
  },
  profile: {},
};

function setUser(state = INITIAL_STATE, action) {
  return {
    ...state,
    name: action.name,
    email: action.email,
    role: action.role,
  };
}

function getProfileSucess(state = INITIAL_STATE, action) {
  return {
    ...state,
    data: action.data,
    error: '',
    loading: false,
  };
}

function clearError(state = INITIAL_STATE) {
  return { ...state, error: '', loading: false, send: false };
}

function genericRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function loginSuccess(state = INITIAL_STATE, action) {
  return { ...state, ...action.user, error: '', loading: false };
}

function genericError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function updateProfileSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    data: action.data,
    error: '',
    loading: false,
  };
}

function genericSendSuccess(state = INITIAL_STATE) {
  return { ...state, error: '', loading: false, send: true };
}

function logout() {
  return INITIAL_STATE;
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: setUser,
  [Types.CLEAR_ERROR]: clearError,
  [Types.LOGIN_REQUEST]: genericRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_ERROR]: genericError,
  [Types.GET_PROFILE_REQUEST]: genericRequest,
  [Types.GET_PROFILE_SUCCESS]: getProfileSucess,
  [Types.GET_PROFILE_ERROR]: genericError,
  [Types.CONFIRM_CODE_REQUEST]: genericRequest,
  [Types.SEND_NEW_CODE_REQUEST]: genericRequest,
  [Types.SEND_NEW_CODE_SUCCESS]: genericSendSuccess,
  [Types.SEND_NEW_CODE_ERROR]: genericError,
  [Types.UPDATE_PROFILE_REQUEST]: genericRequest,
  [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [Types.UPDATE_PROFILE_ERROR]: genericError,
  [Types.SEND_RECOVERY_CODE_REQUEST]: genericRequest,
  [Types.SEND_RECOVERY_CODE_SUCCESS]: genericSendSuccess,
  [Types.SEND_RECOVERY_CODE_ERROR]: genericError,
  [Types.RECOVERY_REQUEST]: genericRequest,
  [Types.RECOVERY_SUCCESS]: genericSendSuccess,
  [Types.RECOVERY_ERROR]: genericError,
  [Types.SEND_NEW_ACTIVATE_CODE_REQUEST]: genericRequest,
  [Types.SEND_NEW_ACTIVATE_CODE_SUCCESS]: genericSendSuccess,
  [Types.SEND_NEW_ACTIVATE_CODE_ERROR]: genericError,
  [Types.ACTIVATE_REQUEST]: genericRequest,
  [Types.ACTIVATE_SUCCESS]: genericSendSuccess,
  [Types.ACTIVATE_ERROR]: genericError,
  [Types.LOGOUT]: logout,
});
