import React from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '~/assets/images/logo-404.svg';

import { SubTitle, Link } from '~/components/Text';
import {
  Container,
  LogoContent,
  BackContent,
  TextContent,
  StyledButton,
} from './styles';

function NotFound() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const to = decodeURIComponent(params.get('to'));

  return (
    <Container>
      <LogoContent>
        <Logo />
      </LogoContent>
      <BackContent>
        <Link to={to}>
          <StyledButton type="button">Ir para página inicial</StyledButton>
        </Link>
      </BackContent>
      <TextContent>
        <SubTitle>Erro 404, página não encontrada.</SubTitle>
      </TextContent>
    </Container>
  );
}

export default NotFound;
