import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MdSettings, MdArrowBack } from 'react-icons/md';

import UserActions from '~/store/modules/user/duck';
import { useClickOutside } from '~/hooks/useClickOutside';
import { Text } from '~/components/Text';
import { Options, Option, Arrow } from './styles';

function MenuOption(props) {
  const menuOptionRef = useRef();
  const dispatch = useDispatch();
  const { active, setActive } = props;

  useClickOutside(menuOptionRef, () => setActive(false));

  function logout() {
    setActive(false);
    dispatch(UserActions.logout());
  }

  return (
    <Options className={active && 'show'} active={active} ref={menuOptionRef}>
      <Arrow />
      <Option className="settings" to="/profile">
        <MdSettings />
        <Text>Configurações</Text>
      </Option>
      <Option className="logout" to="/" onClick={logout}>
        <MdArrowBack />
        <Text>Sair</Text>
      </Option>
    </Options>
  );
}

MenuOption.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default MenuOption;
