import React from 'react';

import { LoaderContainer, LoaderImage } from './styles';

const Loader = () => (
  <LoaderContainer>
    <LoaderImage />
  </LoaderContainer>
);

export default Loader;
