import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.aside`
  width: ${(props) => props.theme.sizes.sideBar};
  z-index: 100;
  height: 100vh;
  padding: 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 1px 8px #00000033, 0px 3px 3px #0000001f,
    0px 3px 4px rgba(0, 0, 0, 0.12);

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: ${(props) => props.theme.sizes.title};
  height: ${(props) => props.theme.sizes.title};
  margin-right: 5px;
`;

export const MenuList = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 2rem 0;
  list-style: none;
`;

export const MenuItem = styled.li`
  width: 100%;
  display: block;
  align-self: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const MenuLink = styled(NavLink)`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    color: #fff;
  }

  &.active {
    background-color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    color: #fff;
  }

  svg {
    font-size: 1.2rem;
    margin-right: 5px;
  }
`;
