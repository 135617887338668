import { all, takeLatest, call, put } from 'redux-saga/effects';

import api from '~/services/api';
import handleError from '~/adapters/httpErrors';
import PermissionsActions, { PermissionsTypes } from './duck';

function singular(name) {
  if (name.endsWith('ies')) return name.replace(/ies$/, 'y');
  return name.replace(/s$/, '');
}

export function orderByType(permissionsArray) {
  const permissions = {};

  permissionsArray.forEach((permission) => {
    const { name, description, id } = permission;
    const [type, key] = name.split(':');

    if (!permissions[singular(key)]) permissions[singular(key)] = [];

    permissions[singular(key)].push({
      id,
      description,
      type,
    });
  });

  const optionsTypes = [
    {
      name: 'nothing',
      excludes: ['show', 'index', 'store', 'update', 'delete'],
      min: 0,
      description: 'Nenhuma',
    },
    {
      name: 'index',
      excludes: ['store', 'update', 'delete'],
      min: 1,
      description: 'Ler',
    },
    {
      name: 'store',
      excludes: ['update', 'delete'],
      min: 2,
      description: 'Ler e criar',
    },
    {
      name: 'index&update',
      excludes: ['store', 'delete'],
      min: 2,
      description: 'Ler, atualizar',
    },
    {
      name: 'update',
      excludes: ['store'],
      min: 3,
      description: 'Ler, atualizar e deletar',
    },
    {
      name: 'delete',
      excludes: [],
      min: 5,
      description: 'Ler, criar, atualizar e deletar',
    },
  ];

  const canAddOption = (type, option) => {
    return !option.excludes.includes(type);
  };

  const getOptions = (currentOption) => {
    const allOptions = optionsTypes.map((optionType) => {
      return {
        name: optionType.name,
        min: optionType.min,
        description: optionType.description,
        values: currentOption.filter((option) =>
          canAddOption(option.type, optionType)
        ),
      };
    });

    const filterOptions = allOptions.filter(
      (option) => option.values.length >= option.min
    );

    return filterOptions;
  };

  const formattedPermissions = Object.keys(permissions).map((item) => ({
    name: item,
    options: getOptions(permissions[item]),
  }));

  return formattedPermissions;
}

export function* getPermissions() {
  try {
    const response = yield call(api.get, `/permissions`);
    const { data } = response;
    yield put(PermissionsActions.getPermissionsSuccess(orderByType(data)));
  } catch (err) {
    const error = handleError(err);
    yield put(PermissionsActions.getPermissionsError(error));
  }
}

export default all([
  takeLatest(PermissionsTypes.GET_PERMISSIONS_REQUEST, getPermissions),
]);
