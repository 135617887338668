import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MenuItem, MenuLink } from './styles';

function MenuOption(props) {
  const location = useLocation();
  const { to, children, exact } = props;
  const isSelected = exact ? to === location.pathname : true;

  return (
    <MenuItem>
      <MenuLink selected={isSelected} to={to}>
        {children}
      </MenuLink>
    </MenuItem>
  );
}

MenuOption.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  exact: PropTypes.bool.isRequired,
};

export default MenuOption;
