import styled from 'styled-components';
import illustrationBg from '~/assets/images/404.svg';
import { Button } from '~/components/Form/Buttons';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template:
    'logo back' 1fr
    '. text' 1fr
    / 1fr 1fr;
  padding: 45px 70px;

  background: ${(props) => props.theme.colors.primary} url(${illustrationBg})
    right bottom no-repeat;
`;

export const LogoContent = styled.div`
  grid-area: logo;
  justify-self: start;
  align-self: start;
`;

export const BackContent = styled.div`
  grid-area: back;
  justify-self: end;
  align-self: start;
`;

export const TextContent = styled.div`
  grid-area: text;
  justify-self: end;
  align-self: end;
`;

export const StyledButton = styled(Button)`
  background-color: #fff;
`;
