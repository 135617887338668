import styled from 'styled-components';

export const Container = styled.div`
  padding-top: ${(props) => props.theme.sizes.header};
  background-color: ${(props) => props.theme.colors.page};
  padding-left: ${(props) => props.theme.sizes.sideBar};
  min-height: 100vh;
`;

export const Content = styled.div`
  padding: 15px 45px;
  margin: 0px auto;

  @media screen and (max-width: 767px) {
    padding: 0;
    margin: 0;
  }
`;
