import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import history from '~/services/history';

import UserActions from '~/store/modules/user/duck';
import Row from '~/components/Row';
import { Text } from '~/components/Text';
import Error from '~/components/Form/Error';
import { Button, ButtonContainer } from '~/components/Form/Buttons';
import { FieldContainer, Label, Field } from '~/components/Form/Fields';

import { Container, StyledBox, FormTitle } from './styles';

function PasswordRecovery() {
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.user.loading);
  const error = useSelector((store) => store.user.error);
  const send = useSelector((store) => store.user.send);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Email é necessario'),
  });
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  function onSubmit(values) {
    dispatch(UserActions.clearError());

    dispatch(UserActions.sendRecoveryCodeRequest(values.email));
  }

  useEffect(() => {
    dispatch(UserActions.clearError());
  }, [dispatch]);

  return (
    <Container>
      <StyledBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldContainer padding="1rem 0 0">
            <Row justify="center">
              <FormTitle>Recuperação de senha</FormTitle>
            </Row>
          </FieldContainer>

          <FieldContainer padding="1rem 0 0">
            <Label className="required">Email</Label>
            <Field type="email" name="email" ref={register} />

            <ErrorMessage errors={errors} name="email">
              {({ message }) => <Error>{message}</Error>}
            </ErrorMessage>

            {error && <Error>{error}</Error>}
            {send && <Text>Verifique o seu email para recuperação</Text>}
          </FieldContainer>

          <ButtonContainer justifyContent="flex-end" padding="2rem 0 0">
            <Button
              themeType="secondary"
              onClick={() => history.push('/')}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button themeType="primary" type="submit" disabled={loading}>
              Enviar
            </Button>
          </ButtonContainer>
        </form>
      </StyledBox>
    </Container>
  );
}

export default PasswordRecovery;
