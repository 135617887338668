import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  addEmployeeRequest: ['data'],
  addEmployeeSuccess: [],
  addEmployeeError: ['error'],
  getEmployeesRequest: ['filters'],
  getEmployeesSuccess: ['employees', 'next'],
  getEmployeesError: ['error'],
  getEmployeeRequest: ['id'],
  getEmployeeSuccess: ['data'],
  getEmployeeError: ['error'],
  updateEmployeeRequest: ['data'],
  updateEmployeeSuccess: ['data'],
  updateEmployeeError: ['error'],
  setPage: ['page'],
  clearEmployees: [],
});

export const EmployeesTypes = Types;
export default Creators;

const INITIAL_STATE = {
  data: [],
  selectedEmployee: {},
  loading: false,
  error: '',
  page: 1,
  next: null,
};

function addEmployeeRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function addEmployeeSuccess(state = INITIAL_STATE) {
  return { ...state, loading: false, error: '' };
}

function addEmployeeError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function getEmployeesRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function getEmployeesSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    data: action.employees,
    page: state.page + 1,
    next: action.next,
    loading: false,
    error: '',
  };
}

function getEmployeesError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function getEmployeeRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function getEmployeeSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    selectedEmployee: action.data,
    loading: false,
  };
}

function getEmployeeError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function updateEmployeeRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function updateEmployeeSuccess(state = INITIAL_STATE, action) {
  return { ...state, selectedEmployee: action.data, loading: false };
}

function updateEmployeeError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function setPage(state = INITIAL_STATE, action) {
  return { ...state, page: action.page };
}

function clearEmployees() {
  return INITIAL_STATE;
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_EMPLOYEE_REQUEST]: addEmployeeRequest,
  [Types.ADD_EMPLOYEE_SUCCESS]: addEmployeeSuccess,
  [Types.ADD_EMPLOYEE_ERROR]: addEmployeeError,
  [Types.GET_EMPLOYEES_REQUEST]: getEmployeesRequest,
  [Types.GET_EMPLOYEES_SUCCESS]: getEmployeesSuccess,
  [Types.GET_EMPLOYEES_ERROR]: getEmployeesError,
  [Types.GET_EMPLOYEE_REQUEST]: getEmployeeRequest,
  [Types.GET_EMPLOYEE_SUCCESS]: getEmployeeSuccess,
  [Types.GET_EMPLOYEE_ERROR]: getEmployeeError,
  [Types.UPDATE_EMPLOYEE_REQUEST]: updateEmployeeRequest,
  [Types.UPDATE_EMPLOYEE_SUCCESS]: updateEmployeeSuccess,
  [Types.UPDATE_EMPLOYEE_ERROR]: updateEmployeeError,
  [Types.SET_PAGE]: setPage,
  [Types.CLEAR_EMPLOYEES]: clearEmployees,
});
