import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';

import history from '~/services/history';

import Row from '~/components/Row';

import MenuOptions from './MenuOptions';

import { IconButton } from '~/components/Form/Buttons';
import { Container, Headline, IconContent, Icon } from './styles';

function Header() {
  const [option, setOption] = useState(false);
  const goTo = useSelector((store) => store.dashboard.goTo);
  const title = useSelector((store) => store.dashboard.page);

  function handleGoBack() {
    history.push(goTo.destination);
  }

  return (
    <Container>
      <Row>
        {goTo.enable && (
          <IconButton size="24px" onClick={handleGoBack}>
            <MdArrowBack />
          </IconButton>
        )}
        <Headline>
          {title.map((page, index) => {
            if (index === 0) return <span key={String(index)}>{page}</span>;

            if (index === title.length - 1)
              return <span key={String(index)}> / {page} </span>;

            if (title.length >= 3 && String(page).length >= 15)
              return (
                <span title={page} key={String(index)}>
                  {' '}
                  / ...
                </span>
              );

            return <span key={String(index)}>{page}</span>;
          })}
        </Headline>
      </Row>

      <Row justify="flex-end">
        <div>
          <IconContent>
            <Icon
              onClick={() => setOption(!option)}
              className={option && 'active'}
            />
            <MenuOptions active={option} setActive={setOption} />
          </IconContent>
        </div>
      </Row>
    </Container>
  );
}

export default Header;
