export const permissionsArray = [
  'show:role',
  'index:role',
  'store:role',
  'update:role',
  'delete:role',
  'show:employee',
  'index:employee',
  'store:employee',
  'update:employee',
  'delete:employee',
  'show:company',
  'index:company',
  'store:company',
  'update:company',
  'delete:company',
  'show:companyContact',
  'index:companyContact',
  'store:companyContact',
  'update:companyContact',
  'delete:companyContact',
  'store:license',
  'index:license',
  'show:license',
  'update:license',
  'delete:license',
];

export function checkPermission(userRole, permissionsVerify) {
  const { permissions } = userRole;

  if (!permissionsVerify) return true;

  return permissions.some(
    (permission) => permissionsVerify === permission.name
  );
}
