import { toast } from 'react-toastify';
import api from '~/services/api';

export default async function getCodeInfo(code) {
  try {
    const { data } = await api.get(`/codes/${code}`);
    return data;
  } catch (err) {
    if (!err.response) {
      toast.error('Houve um problema com a conexão por favor verifique');
      return {};
    }

    toast.error('Este código não existe');
    return {};
  }
}
