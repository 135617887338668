import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import handleError from '~/adapters/httpErrors';
import UserActions, { UserTypes } from './duck';

import history from '~/services/history';

export function* login(action) {
  try {
    const response = yield call(api.post, '/sessions', action.data);
    const { user, token } = response.data;
    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(UserActions.loginSuccess({ ...user, token }));

    history.push('/');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.loginError(error));
  }
}

export function* getProfile(action) {
  try {
    const { data } = action;
    const response = yield call(api.get, '/profile', data);
    yield put(UserActions.getProfileSuccess(response.data));
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.getProfileError(error));
  }
}

export function* updateProfile(action) {
  try {
    const { old_password: oldPassword, password } = action.data;
    const data = {
      ...action.data,
      old_password: oldPassword || null,
      password: password || null,
    };
    const response = yield call(api.put, '/profile', data);
    yield put(UserActions.updateProfileSuccess(response.data));
    toast.success('Dados atualizados com sucesso.');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.updateProfileError(error));
  }
}

export function* sendRecoveryCode(action) {
  try {
    const data = { email: action.email };
    yield call(api.post, '/recovery', data);
    yield put(UserActions.sendRecoveryCodeSuccess());
    toast.success(
      'Um link de recuperação de senha foi enviado para o seu e-mail.'
    );
    history.push('/login');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.sendRecoveryCodeError(error));
  }
}

export function* recoveryRequest(action) {
  try {
    const data = { code: action.code, password: action.password };

    yield call(api.put, '/recovery', data);

    toast.success('Senha recuperada.');
    yield put(UserActions.recoverySuccess());
    history.push('/login');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.recoveryError(error));
  }
}

export function* sendNewActivateCode(action) {
  try {
    const data = { code: action.code };
    yield call(api.post, '/activate', data);

    yield put(UserActions.sendNewActivateCodeSuccess());
    toast.success('Um link de ativação foi enviado para seu e-mail.');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.sendNewActivateCodeError(error));
  }
}

export function* activateRequest(action) {
  try {
    const data = { code: action.code, password: action.password };
    yield call(api.put, '/activate', data);
    yield put(UserActions.activateSuccess());
    history.push('/login');
    toast.success('Conta ativada com sucesso.');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.recoveryError(error));
  }
}

export function* confirmCode(action) {
  try {
    const data = { code: action.code, password: action.password };
    const response = yield call(api.put, '/confirmation', data);
    const { user, token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(UserActions.loginSuccess({ ...user, token }));

    history.push('/');
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.loginError(error));
  }
}

export function* sendNewCode(action) {
  try {
    const data = { code: action.code };
    yield call(api.post, '/confirmation', data);
    yield put(UserActions.sendNewCodeSuccess());
    toast.success(
      'Um link de recuperação de senha foi enviado para o seu e-mail.'
    );
  } catch (err) {
    const error = handleError(err);
    yield put(UserActions.sendNewCodeError(error));
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.user;

  if (token) api.defaults.headers.Authorization = `Bearer ${token}`;
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(UserTypes.LOGIN_REQUEST, login),
  takeLatest(UserTypes.GET_PROFILE_REQUEST, getProfile),
  takeLatest(UserTypes.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(UserTypes.CONFIRM_CODE_REQUEST, confirmCode),
  takeLatest(UserTypes.SEND_NEW_CODE_REQUEST, sendNewCode),
  takeLatest(UserTypes.RECOVERY_REQUEST, recoveryRequest),
  takeLatest(UserTypes.SEND_RECOVERY_CODE_REQUEST, sendRecoveryCode),
  takeLatest(UserTypes.SEND_NEW_ACTIVATE_CODE_REQUEST, sendNewActivateCode),
  takeLatest(UserTypes.ACTIVATE_REQUEST, activateRequest),
]);
