import styled from 'styled-components';
import Box from '~/components/Box';
import { FieldContainer } from '~/components/Form/Fields';

import { Title } from '~/components/Text';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 2rem;
`;

export const StyledBox = styled(Box)`
  padding: 1rem;
  min-width: 34vw;
`;

export const FormTitle = styled(Title)`
  margin-bottom: 22px;
`;

export const StyledFieldContainer = styled(FieldContainer)`
  margin-top: 10px;
`;
