import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  addCompanyContactRequest: ['data'],
  addCompanyContactSuccess: [],
  addCompanyContactError: ['error'],
  getCompanyContactRequest: ['id'],
  getCompanyContactSuccess: ['employee'],
  getCompanyContactError: ['error'],
  updateCompanyContactRequest: ['data'],
  updateCompanyContactSuccess: ['employee'],
  updateCompanyContactError: ['error'],
  clearCompanyContact: [],
});

export const CompanyContactTypes = Types;
export default Creators;

const INITIAL_STATE = {
  data: {},
  selectedCompanyContact: {},
  loading: false,
  error: '',
};

function addCompanyContactSuccess(state = INITIAL_STATE) {
  return {
    ...state,
    loading: false,
    error: '',
  };
}

function getCompanyContactSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    selectedCompanyContact: action.employee,
    loading: false,
    error: '',
  };
}

function updateCompanyContactSuccess(state = INITIAL_STATE, action) {
  return {
    ...state,
    selectedCompanyContact: action.employee,
    loading: false,
    error: '',
  };
}

function genericRequest(state = INITIAL_STATE) {
  return { ...state, loading: true };
}

function genericError(state = INITIAL_STATE, action) {
  return { ...state, loading: false, error: action.error };
}

function clearCompanyContact() {
  return INITIAL_STATE;
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_COMPANY_CONTACT_REQUEST]: genericRequest,
  [Types.ADD_COMPANY_CONTACT_SUCCESS]: addCompanyContactSuccess,
  [Types.ADD_COMPANY_CONTACT_ERROR]: genericError,
  [Types.GET_COMPANY_CONTACT_REQUEST]: genericRequest,
  [Types.GET_COMPANY_CONTACT_SUCCESS]: getCompanyContactSuccess,
  [Types.GET_COMPANY_CONTACT_ERROR]: genericError,
  [Types.UPDATE_COMPANY_CONTACT_REQUEST]: genericRequest,
  [Types.UPDATE_COMPANY_CONTACT_SUCCESS]: updateCompanyContactSuccess,
  [Types.UPDATE_COMPANY_CONTACT_ERROR]: genericError,
  [Types.CLEAR_COMPANY_CONTACT]: clearCompanyContact,
});
