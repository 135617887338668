import { all, takeLatest, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';
import handleError from '~/adapters/httpErrors';
import CompanyContactActions, { CompanyContactTypes } from './duck';

export function* getCompanyContact(action) {
  try {
    const { id } = action;
    const response = yield call(api.get, `/contacts/${id}`);

    if (response.data.error) {
      const { error } = response.data;
      yield put(CompanyContactActions.getCompanyContactError(error));
      toast.error(error);
      return;
    }

    const employee = response.data;
    yield put(CompanyContactActions.getCompanyContactSuccess(employee));
  } catch (err) {
    const error = handleError(err);
    yield put(CompanyContactActions.getCompanyContactError(error));
  }
}

export function* addCompanyContact(action) {
  try {
    const { data } = action;
    const response = yield call(api.post, '/contacts', data);
    const employee = response.data;
    yield put(CompanyContactActions.addCompanyContactSuccess(employee));
    toast.success('Usuário(a) criado(a) com sucesso.');
  } catch (err) {
    const error = handleError(err);
    yield put(CompanyContactActions.addCompanyContactError(error));
  }
}

export function* updateCompanyContact(action) {
  try {
    const { id, values } = action.data;

    const response = yield call(api.put, `/contacts/${id}`, values);
    const { data } = response;

    yield put(CompanyContactActions.updateCompanyContactSuccess(data));
    toast.success('Usuário(a) atualizado(a) com sucesso!');
  } catch (err) {
    const error = handleError(err);
    yield put(CompanyContactActions.updateCompanyContactError(error));
  }
}

export default all([
  takeLatest(
    CompanyContactTypes.GET_COMPANY_CONTACT_REQUEST,
    getCompanyContact
  ),
  takeLatest(
    CompanyContactTypes.ADD_COMPANY_CONTACT_REQUEST,
    addCompanyContact
  ),
  takeLatest(
    CompanyContactTypes.UPDATE_COMPANY_CONTACT_REQUEST,
    updateCompanyContact
  ),
]);
